import request from '@utils/http'

//流水记录
export function exportFlowList(data) {
    return request({
        url: "/carton/stock/flow/record/report",
        method: 'post',
        data
    })
}

//数据统计报表
export function exportWebReport(data) {
    return request({
        url: "/carton/report/web/census/report",
        method: 'post',
        data
    })
}

//销售毛利导出
export function exportWebProfit(data) {
    return request({
        url: "/carton/report/web/sale/profit/report",
        method: 'post',
        data
    })
}