<template>
  <el-button class="zh-btn" @click="handleClickExport" :disabled="disable">
    <export-img :class="disable?'disable':''"></export-img>
    <span class="ml_8">导出</span>
  </el-button>
  <export-dia :formData="props.formData" ref="exportShow"></export-dia>
</template>

<script setup>
import ExportImg from "@components/exportImg.vue";
import exportDia from "./components/exportDia.vue";
import {computed, provide, ref, toRefs} from "vue";

const exportShow = ref(null)
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    }
  },
  type: {
    type: String,
    default: 'flow',//flow:流量报表，census:订单报表 //inventory 库存下载
  },
  listLength: {
    type: Number,
    default: 0
  }
})
const {formData, type, listLength} = toRefs(props)
const disable = computed(() => {
  return listLength.value === 0 || (type.value !== 'inventory' && !formData.value.time)
})
provide('formData', formData)
provide('type', type)
const handleClickExport = () => {
  exportShow.value.openExport()
}

</script>

<style lang="scss" scoped>

</style>