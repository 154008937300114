<template>
  <div>
    <el-dialog
        v-model="showExport"
        title="提示"
        width="480"
        class="export-dia"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        destroy-on-close
        align-center
        @open="openExport"
    >
      <div class="export-content">
        <div class="export-title mr_16">文件名称</div>
        <el-input placeholder="请填写导出的名称" class="w280" v-model="valueName" maxlength="20" v-elInput></el-input>
      </div>
      <template #footer>
        <el-button class="zh-btn btn1 w160" @click="closeExport">取消</el-button>
        <el-button type="primary" class="btn1 w160 ml_32" @click='handleExport' :loading="loading">
          {{ loading ? '导出中，请稍后' : '确认导出' }}
        </el-button>
      </template>
    </el-dialog>
  </div>

</template>

<script setup>
import {getCurrentInstance, inject, ref} from "vue";
import {exportKey} from "../config";
import ExportNew from '@utils/excel/export.js'
import moment from "moment";
import {cloneDeep} from "lodash";
import {columnsStock} from "@views/inStock/inventoryCheck/components/config";
import {resetStockGoodsList} from "@utils/tool";

const column = columnsStock.filter(item => item.fieldKey !== 'img')

const loading = ref(false)

const {proxy} = getCurrentInstance()
const showExport = ref(false)
const formData = inject('formData')
const type = inject('type')
const valueName = ref('')

const openExport = () => {
  setName()
  showExport.value = true
}

//设置文件名称
function setName() {
  valueName.value = `${moment().format('YYYY-MM-DD')}-${exportKey[type.value].downTitle}`
}

function setTime() {
  let beginTime = 0
  let endTime = 0
  let time = cloneDeep(formData.value.time)
  switch (type.value) {
    case 'flow':
      if (time && time[0]) {
        beginTime = moment(proxy.$filters.timeFormat(time[0], 2) + ' 00:00:00').valueOf()
      }
      if (formData.value.time && formData.value.time[1]) {
        endTime = moment(proxy.$filters.timeFormat(time[1], 2) + ' 23:59:59').valueOf()
      }
      break
    case 'census':
      if (time && time[0]) {
        beginTime = moment(time[0]).format('YYYYMMDD')
      }
      if (time && time[1]) {
        endTime = moment(time[1]).format('YYYYMMDD')
      }
      break
    case 'profit':
      if (time && time[0]) {
        beginTime = moment(time[0]).format('YYYYMMDD')
      }
      if (time && time[1]) {
        endTime = moment(time[1]).format('YYYYMMDD')
      }
      break
  }
  return {
    beginTime,
    endTime
  }
}

//重写数组
function setList(item) {
  switch (type.value) {
    case 'flow':
      item.operateTime = proxy.$filters.timeFormat(item.operateTime, 1)
      break
    case 'census':
      filterPrice(item)
      break
    case 'profit':
      filterPrice(item)
      break
    case 'inventory':
      item.lastTime = item.lastTime ? proxy.$filters.timeFormat(item.lastTime, 1) : '--'
      filterPrice(item)
      break
  }
}

function filterPrice(obj) {
  //盘点传出的obj对象里面的key 包含price的，需要转换成元
  Object.keys(obj).forEach(key => {
    if (key.toUpperCase().includes('PRICE')) {
      obj[key] = proxy.$filters.toYuan(obj[key], '元')
    }
  })
}

//确认导出
const handleExport = () => {
  let {beginTime, endTime} = setTime()
  let obj = {}
  loading.value = true
  if(formData.value.orderType){
    //判断orderType是否是数组
    if(Array.isArray(formData.value.orderType)){
      obj = {
        orderType: formData.value.orderType[0],
        typeId: formData.value.orderType[1]
      }
    }else {
      obj = {
        orderType: formData.value.orderType
      }
    }
  }
  if (type.value !== 'inventory') {
    obj = {
      ...formData.value,
      ...obj,
      beginTime: beginTime,
      endTime: endTime,
      thirdId: formData.value.supplier?.[0]?.supplierId || formData.value.punter?.[0]?.punterId || '',
    }
    exportKey[type.value].api(obj).then(res => {
      loading.value = false
      if (res.code !== 0) {
        proxy.$message.error(res.msg)
        return
      }
      let resultList = res.data?.resultList || []
      resultList.forEach((item, index) => {
        setList(item)
        item.index = index + 1
        // if (item.scattered) {
        //   item.unitName = item.scatteredName + "（拆）"
        // }
      })
      let titleList = res.data?.excelTitles || []
      titleList.unshift({
        title: '序号',
        fieldKey: 'index',
        sort: 0
      })
      titleList.forEach(item => {
        if (item.title.indexOf('金额') !== -1) item.title = item.title + '(元)'
      })
      ExportNew.export2Excel(resultList, titleList, valueName.value, true)
      showExport.value = false
    })
  } else {
    obj = {
      ...formData.value,
      supplierId: formData.value.supplier[0]?.supplierId || "",
      brandId: formData.value.brand[0]?.brandId || "",
      stockId: formData.value.stockId || '',
      query: formData.value.query,
      pageSize: 100000,
      pageNumber: 1,
      sortField: formData.value.sortField,
      sortType: formData.value.sortType,
      scattered: formData.value.scattered,
      classifyId: formData.value.classifyId?.length > 0 ? formData.value.classifyId[formData.value.classifyId.length - 1] : ''
    }
    exportKey[type.value].api(obj).then(res => {
      loading.value = false
      if (res.code !== 0) {
        proxy.$message.error(res.msg)
        return
      }
      let resultList = res.data?.list || []
      resultList = resetStockGoodsList(resultList)
      resultList.forEach((item, index) => {
        setList(item)
        item.index = index + 1
        if (item.scatteredName) {
          item.unitName = item.scattered ? item.scatteredName + "（零）" : item.scatteredName + "（整）"
        }
      })

      let titleList = column || []
      titleList.unshift({
        title: '序号',
        fieldKey: 'index',
        sort: 0
      })
      ExportNew.export2Excel(resultList, titleList, valueName.value, true, [1, 2, 3, 5, 6, 7, 10, 13])
      showExport.value = false
    })
  }
}

const closeExport = () => {
  showExport.value = false
}

defineExpose({
  openExport
})

</script>

<style lang="scss" src="./exportDia.scss" scoped>

</style>