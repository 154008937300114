import {exportFlowList, exportWebProfit, exportWebReport} from "@utils/api/export";
import {insStockList} from '@/utils/api/inStock/inventory.js'

export const exportKey = {
    //流水记录
    flow: {
        downTitle: '流水记录',
        api: exportFlowList,
    },
    census: {
        downTitle: '数据统计',
        api: exportWebReport,
    },
    profit: {
        downTitle: '销售毛利',
        api: exportWebProfit,
    },
    inventory: {
        downTitle: '库存列表',
        api: insStockList
    }
}
